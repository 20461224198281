<template>
	<div class="hand_card_detail">
		<table cellspacing="0" cellpadding="0" width="100%" id="print">
			<tr align="center" height="50px">
				<td bgcolor="#EBECEE" width="76px">序号</td>
				<td bgcolor="#D0D1D2" width="172px">
					产品图
				</td>
				<td bgcolor="#EBECEE" width="398px" class="skutd" style="width:398px;">
					商品规格(SKU)
				</td>
				<td bgcolor="#D0D1D2" width="181px">
					直播到手价
				</td>
				<td bgcolor="#EBECEE" width="202px">
					随单赠品
				</td>
				<td bgcolor="#D0D1D2" width="181px">
					直播间抽奖
				</td>
				<td bgcolor="#EBECEE" width="202px">
					生产日期-保质期
				</td>
			</tr>
			<tr bgcolor="#F7F8F9" height="155px">
				<td
				
					align="center"
					style="font-size: 26px; color: rgb(255, 81, 81);"
				>
					{{data.weigh}}
				</td>
				<td align="center">
					<img
					
						width="118"
						height="118"
						:src="data.pict_url"
					/>
				</td>
				<td style="padding: 10px 17px;">
					<div v-html="skuInfo">
					</div>
				</td>
				<td style="padding: 0px 17px;" v-if="data.zb_price == data.zb_e_price">
					直播到手价：￥{{data.zb_price}}
				</td>
				<td style="padding: 0px 17px;" v-else>
					直播到手价：￥{{data.zb_price}}~￥{{data.zb_e_price}}
				</td>
				<td style="padding: 0px 17px;">{{data.zp}}</td>
				<td style="padding: 0px 17px;">{{data.zb_cj}}</td>
				<td style="padding: 0px 17px;">
					<div>生产日期：</div>
					<div>{{data.sc_day}}</div>
					<div>保质期：</div>
					<div>{{data.dayInfo}}</div>
				</td>
			</tr>
			<tr align="center" height="50px">
				<td colspan="7" bgcolor="#D0D1D2">
					产品策划
				</td>
			</tr>
			<tr height="50px">
				<td
				
					colspan="3"
					bgcolor="#F7F8F9"
					align="center"
					style="color: rgb(16, 16, 17);"
				>
					{{data.nick}}
				</td>
				<td
				
					colspan="4"
					rowspan="7"
					bgcolor="#F7F8F9"
					style="padding: 30px 40px;"
				>
					<div v-html="data.content">
					</div>
				</td>
			</tr>
			<tr height="50px">
				<td
				
					colspan="3"
					bgcolor="#EBECEE"
					align="center"
				>
					商品信息
				</td>
			</tr>
			<tr height="100px">
				<td
				
					colspan="3"
					bgcolor="#F7F8F9"
					style="padding: 0px 17px; color: rgb(16, 16, 17);"
				>
					<div></div>
					<div>
						{{data.title}}
					</div>
				</td>
			</tr>
			<tr height="50px">
				<td
				
					colspan="2"
					bgcolor="#D0D1D2"
					align="center"
				>
					不包邮地区
				</td>
				<td bgcolor="#EBECEE" align="center">
					是否配合控库存
				</td>
			</tr>
			<tr height="50px" bgcolor="#F7F8F9">
				<td
				
					colspan="2"
					align="center"
					style="color: rgb(16, 16, 17);"
				>
					{{data.bby}}
				</td>
				<td align="center">{{data.skuInfo}}</td>
			</tr>
			<tr height="50px">
				<td
				
					colspan="2"
					align="center"
					bgcolor="#EBECEE"
				>
					商品二维码
				</td>
				<td align="center" bgcolor="#D0D1D2">
					现货库存
				</td>
			</tr>
			<tr>
				<td
				
					colspan="2"
					bgcolor="#F7F8F9"
					align="center"
				>
					<div

						id="qrCode"
						ref="qrCodeDiv"
						style="text-align: center; width: 150px; margin: 0px auto;"
						title=""
					>

					</div>
				</td>
				<td bgcolor="#F7F8F9" align="center">
					{{data.sku}}
				</td>
			</tr>
			<tr height="50px">
				<td
				
					colspan="7"
					bgcolor="#F7F8F9"
					class="last"
					style="padding: 0px 17px;"
				>
					<span>排期主题：{{data.zb_name}}</span> <span>策划人：{{data.username}}</span> <span>直播日期：{{data.zbtime}}</span>
				</td>
			</tr>
		</table>
		<ul class="opera flex_c f_jc_c">
			<li>
				<el-button type="primary" @click="print">打印</el-button>
			</li>
			<li class="mt20 mb20">
				<el-button type="primary" @click="prev">上一个</el-button>
			</li>
			<li>
				<el-button type="primary" @click="next">下一个</el-button>
			</li>
		</ul>
	</div>
</template>

<script>
	import printJS from "print-js"
	import QRCode from 'qrcodejs2'
	export default {
		name: 'GoodsDetail',
		data() {
			return {
				data: {},
				sku: "",
				id: '',
				skuInfo: '',
				prevId: '',
				nextId: '',
				qrcode: ''
			}
		},
		methods: {
			prev(){
				if(this.prevId == ''){
					this.$message.error("没有上一个");
					return
				}
				this.id = this.prevId
				this.GetDetail()
			},
			next(){
				if(this.nextId == ''){
					this.$message.error("没有下一个");
					return
				}
				this.id = this.nextId
				this.GetDetail()
			},
			bindQRCode: function () {
				if(this.qrcode){
					this.qrcode.clear()
					return
				}
				this.qrcode = new QRCode(this.$refs.qrCodeDiv, {
					text: this.data.item_url,
					width: 150,
					height: 150,
					colorDark: "#333333", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
				})
			},
			print(){
				const style = '@page { margin: 0 } @media print { .noprint{display: none;} }#printCons {width: 600px;} table tr td,th { border-collapse: collapse;padding: 15px;} input {width: 60px;} td {border:1px #eee solid;} .skutd {width:398px;}' +
						'.el-table thead {color: #909399;font-weight: 500;}'
				printJS({
					printable: 'print',
					type: 'html',
					style: style,
					scanStyles: false,
					//properties: [
					//    { field: 'name', displayName: '姓名', columnSize:`50%`},
					//    { field: 'sex', displayName: '性别',columnSize:`50%`},
					//],
					header: '<p class="custom-p" style="text-align:center;font-size: 16px;"> '+this.data.zb_name+' </p>',
					// style: '#printCons {width: 600px;} .no-print{width: 0px} .itemText1 { width: 200px } .itemText2 { width: 200px } .itemText3 { width: 200px } .itemText4 { width: 200px }',
					// gridHeaderStyle:'font-size:12px; padding:3px; border:1px solid; font-weight: 100; text-align:left;',
					// gridStyle:'font-size:12px; padding:3px; border:1px solid; text-align:left;',
					// repeatTableHeader: true,
					// scanStyles:true,
					// targetStyles: ['*'],
					// ignoreElements:['no-print','bc','gb']
				})
			},
			handleClick() {},
			GetDetail(){
				let that = this
				that.data = []
				this.get('?server=live.entr.goods.shou.ka.info.get',{id:this.id}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					// console.log(response)
					that.data = response.data.goods
					let len = response.data.sku.length
					// console.log(len)
					let sku = ""
					for(let i=0;i<len;i++){
						// console.log(response.data.data[i])
						sku += response.data.sku[i] + ","
					}
					that.sku = sku
					that.skuInfo = response.data.skuInfo
					that.prevId = response.data.prev
					that.nextId = response.data.next
					that.$nextTick(function () {
						that.bindQRCode();
					})
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			},
		},
		created() {
			this.id = this.$route.query.id

			if(!this.id){
				this.$message.error('请求非法')
			} else {
				this.GetDetail()
			}
		}
	}
</script>

<style lang="scss" scoped>
.hand_card_detail {
	width: 1270px;
	margin: 0 auto;
}
.table {
	border-collapse: collapse;
}
table, td, th {
	border: 1px solid #999;
	font-size: 17px;
}
table > tr {
    vertical-align: middle;
}
td {
    display: table-cell;
    vertical-align: inherit;
}
p {
	line-height: 1.6;
}
.opera {
	position: fixed;
	top: 60px;
	right: 60px;
}
</style>
